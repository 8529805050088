import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, button } from '../styles/global'
import { parseACF, getGlobalData } from '../utils'
import { BGVideoContext } from '../context/BGVideoContext'

const Words = props => {
    const { previewData, pageData, pageContext } = props
    const data = previewData || pageContext.data
    const globalData = getGlobalData()
    const videoContext = useContext(BGVideoContext)

    useMount(() => {
        if (videoContext.activeVideo !== data.background_video) {
            videoContext.setActiveVideo(data.background_video)
        }
    })

    useEffect(() => {
        if (videoContext.activeVideo !== data.background_video) {
            videoContext.setActiveVideo(data.background_video)
        }
    }, [videoContext])

    const renderSubmissionLinks = () => {
        if (!data.submissions) return

        const items = data.submissions.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/${data.slug}/${item.slug}`} title={item.title}>
                    {i+1}
                </Item>
            )
        })

        return items
    }
    
    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Grid>
                    <Left>
                        <Top>
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data?.description,
                                }}
                            />
                        </Top>

                        <Bottom>
                            <Button
                                as={Link}
                                to={`/`}
                            >
                                {data.title}
                            </Button>

                            <Items>
                                {renderSubmissionLinks()}
                            </Items>
                        </Bottom>
                    </Left>
                    <Right>
                        <TickerWrap
                            href={globalData?.global?.contributing_to_digaest_link}
                            target={'_blank'}
                        >
                            <div className="anim">
                                <p style={{ whiteSpace: 'nowrap', paddingRight: '32px' }}>{globalData?.global?.contributing_to_digaest}</p>
                            </div>
                        </TickerWrap>
                    </Right>
                </Grid>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Button = styled.div``
const Items = styled.div``
const Item = styled.div``


// Layout

const Wrapper = styled.div`
    ${tw`relative w-full h-full`};
`

const Grid = styled.div`
    ${tw`flex h-full`};
`

// Left

const Left = styled.div`
    flex: 1;
    padding-right: 45px;
    ${tw`flex flex-col justify-between w-full h-full`};

    ${media.mob`
        ${tw`pr-0`};
    `}
`

// Right

const Right = styled.div`
    ${tw`relative`};
    width: 32px;
    overflow: hidden;

    ${media.mob`
        display: none;
    `}
`

// Top

const Top = styled.div``


// Bottom

const Bottom = styled.div`
    ${tw`flex flex-wrap items-center w-full`};

    ${Button} {
        ${button};
        ${tw`mr-4`};
    }

    ${Items} {
        ${tw`flex flex-wrap`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Item} {
        ${tw`mx-4 leading-none`};

        ${media.mob`
            ${tw`mt-4`};
        `}
    }
`

const TickerWrap = styled.a`
    ${button}
    ${tw`absolute inset-0 flex px-0`};
    width: calc(100vh - 40px);
    height: 32px;
    overflow: hidden;
    transform-origin: left top;
    transform: rotate(-90deg) translateX(-100%);

    --move-initial: 100vh;
    --move-final: -100%;

    .anim {
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 20s linear infinite;
        animation-play-state: running;
    }

    p {
        padding: 0 32px;
    }

    @keyframes marquee {
        0% {
            transform: translate3d(var(--move-initial), 0, 0);
        }

        100% {
            transform: translate3d(var(--move-final), 0, 0);
        }
    }
`

export default Words
